import { LoadingTableComponent } from "components/common";
import { FC } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Table } from "reactstrap";
import { getFormattedDate } from "utils/functions";

interface IRecentlyCredentialsIssued {
  credentialsList: any;
  Profile: any;
}

const RecentlyCredentialsIssued: FC<IRecentlyCredentialsIssued> = ({
  credentialsList,
  Profile,
}) => {
  return (
    <div className="w-100 d-flex dashboard-data">
      <div className="w-100 list-box rounded-10 mt-20">
        <p className="color-black1 font-20 fw-bold mb-20">
          Recently Credentials Issued
        </p>
        <Scrollbars
          style={{ height: 415 }}
          renderThumbHorizontal={(props) => (
            <div {...props} className="thumb-horizontal" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          autoHide
          className="table-section border-grey"
        >
          <Table stickyHeader>
            <thead>
              <tr>
                <th className="color-black1 font-14 fw-bold">
                  Credential Name
                </th>
                <th className="color-black1 font-14 fw-bold">Holder</th>
                <th className="color-black1 font-14 fw-bold">Status</th>
                <th className="color-black1 font-14 fw-bold">Issued Date</th>
              </tr>
            </thead>
            <tbody className="table-top-border">
              {credentialsList && credentialsList.length > 0 ? (
                credentialsList.map((credential: any) => {
                  return (
                    <tr key={credential._id}>
                      <td className="font-16 color-light-grey">
                        {credential?.schemaData?.name}
                      </td>
                      <td className="font-16 color-light-grey d-flex align-items-center">
                        <div className="holder-profile mr-10">
                          <img
                            src={credential?.userData?.profilePic || Profile}
                            alt="profile"
                          />
                        </div>
                        {credential?.userData?.firstName &&
                        credential?.userData?.lastName
                          ? `${credential?.userData?.firstName} ${credential?.userData?.lastName}`
                          : credential?.userData?.email}
                      </td>
                      <td className="font-16 color-light-grey pl-35 position-relative status-data">
                        <span
                          className={`status ${
                            credential.status === "active"
                              ? "active"
                              : "deactive"
                          }`}
                        ></span>
                        {credential.status.charAt(0).toUpperCase() +
                          credential.status.slice(1)}
                      </td>
                      <td className="font-16 color-light-grey">
                        {getFormattedDate(credential.createdAt)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <LoadingTableComponent />
              )}
            </tbody>
          </Table>
        </Scrollbars>
      </div>
    </div>
  );
};

export default RecentlyCredentialsIssued;
